const clickupRegularSchedule = [
  // 'Maico Triaca',
  // 'Gabriel Land',
  // 'Lucas Torres',
  // 'Bruno Brixius',
  // 'Gustavo Rost',
  // 'Natiele Bastião',
  // 'Henrique Melo',
  // 'Marcelo Esser',
  // 'Nicolas Hoffmann',
  // 'Lucas Kruger'
]

const config = {
  goals: {
    professional: {
      2023: {
        8: 22668,
        9: 23121,
        10: 23584,
        11: 24055,
        12: 24537
      },
      2024: {
        1: 23811,
        2: 24137,
        3: 24741,
        4: 24608,
        5: 25468,
        6: 25338,
        7: 26130,
        8: 27199,
        9: 27278,
        10: 27909,
        11: 28476,
        12: 28467
      },
      2025: {
        1: 23811,
        2: 24137,
        3: 24741,
        4: 24608,
        5: 25468,
        6: 25338,
        7: 26130,
        8: 27199,
        9: 27278,
        10: 27909,
        11: 28476,
        12: 28467
      }
    },
    student: {
      2023: {
        11: 1200,
        12: 1200
      },
      2024: {
        1: 1232,
        2: 1380,
        3: 1545,
        4: 1777,
        5: 2044,
        6: 2350,
        7: 2703,
        8: 3108,
        9: 3575,
        10: 4111,
        11: 4727,
        12: 5437
      },
      2025: {
        1: 1232,
        2: 1380,
        3: 1545,
        4: 1777,
        5: 2044,
        6: 2350,
        7: 2703,
        8: 3108,
        9: 3575,
        10: 4111,
        11: 4727,
        12: 5437
      }
    }
  },
  clickupSchedule: clickupRegularSchedule
}

export default config
